import classNames from 'classnames'
import type { SubscriptionStatus } from '~/modules/auth/types/session'

export const checkDarkMode = () => {
  if (
    localStorage.theme === 'dark' ||
    (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.body.setAttribute('data-mode', 'dark')
  } else {
    document.body.removeAttribute('data-mode')
  }
}

export const setDarkMode = () => {
  localStorage.setItem('theme', 'dark')
  document.body.setAttribute('data-mode', 'dark')
}

export const setLightMode = () => {
  localStorage.setItem('theme', 'light')
  document.body.removeAttribute('data-mode')
}

export const setDefaultMode = () => {
  localStorage.removeItem('theme')
  checkDarkMode()
}

export type Intent =
  | 'success'
  | 'danger'
  | 'info'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'semi-transparent'
  | 'default'

export const getIntentSurface = (intent?: Intent | null) =>
  classNames(
    intent === 'info' && 'text-dark bg-surfaceBlue',
    intent === 'success' && 'text-dark bg-surfacePurple',
    intent === 'warning' && 'text-dark bg-surfaceYellow',
    intent === 'danger' && 'text-dark bg-surfacePink',
    intent === 'primary' && 'text-dark bg-surfaceOrange',
    intent === 'secondary' && 'text-dark bg-surfaceGreen',
    intent === 'outlined' && 'text-dark bg-white border-2 border-dark dark:border-mid',
    intent === 'semi-transparent' && 'text-dark bg-white border-2 border-dark opacity-40 opacity-50',
    (!intent || intent === 'default') && 'text-dark bg-white dark:text-white dark:bg-baseGray'
  )

export const getIntentInteractive = (intent?: Intent) =>
  classNames(
    intent === 'info' && 'text-dark bg-lightBlue hover:bg-baseBlue',
    intent === 'success' && 'text-white bg-lightPurple hover:bg-basePurple',
    intent === 'warning' && 'text-dark bg-lightYellow hover:bg-baseYellow',
    intent === 'danger' && 'text-dark bg-lightPink hover:bg-basePink',
    intent === 'primary' && 'text-dark bg-lightOrange hover:bg-baseOrange',
    intent === 'secondary' && 'text-dark bg-lightGreen hover:bg-baseGreen',
    intent === 'outlined' &&
      'text-dark bg-white border-2 border-dark dark:border-2 dark:border-mid hover:bg-light/30 dark:hover:bg-light dark:border-light',
    intent === 'semi-transparent' &&
      'text-dark bg-white border-2 border-dark dark:border-2 dark:border-mid opacity-40 hover:opacity-50 dark:border-light',
    (!intent || intent === 'default') &&
      'text-white bg-dark hover:bg-baseGray dark:text-white dark:bg-mid dark:hover:bg-baseGray'
  )

export const subscriptionStatusIntent = (status: SubscriptionStatus) => {
  switch (status) {
    case 'trialing':
      return 'info'
    case 'active':
      return 'secondary'
    case 'incomplete':
      return 'warning'
    case 'past_due':
      return 'warning'
    case 'canceled':
      return 'danger'
    case 'incomplete_expired':
      return 'danger'
    case 'unpaid':
      return 'danger'
    default:
      return 'default'
  }
}
